import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Buttons, FormGrid, LoadingIndicator, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { AssigneeChip } from './Assignee';
import { AssignmentsData } from './useStudentsMentorsAssignments';
import { Add } from '@mui/icons-material';


export const MentorAssignmentPanel = styled(FormGrid)`
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
  background: ${props => props.theme.palette.grey[100]};
  box-shadow: ${props => props.theme.shadows[2]};
  border-radius: ${props => props.theme.shape.borderRadius}px;
`;
MentorAssignmentPanel.defaultProps = { columns: "1fr" };


interface Props {
  personKind: "student" | "mentor";
  personId: string;
  assignments: AssignmentsData;
}

export const MentorAssignmentBlock = (props: Props) => {
  const { assignments, personId, personKind } = props;
  const { personAssignments } = assignments;

  const mentors = personAssignments.selected
    ? personAssignments.data
    : assignments.assignmentsByStudent[personId || ""] || [];

  const isAssignmentsLoading = (
    assignments.allAssignments.isLoading
    || (!!personAssignments.selected && personAssignments.isLoading));

  return (
    <MentorAssignmentPanel>
      <Typography variant="caption"><FormattedMessage id="metamentor.student.mentors.label" /></Typography>
      {isAssignmentsLoading &&
        <LoadingIndicator sizeVariant="s" />}
      {!mentors.length && !isAssignmentsLoading &&
        <Typography><FormattedMessage id="metamentor.student.mentors.not_assigned" /></Typography>}

      <Buttons vertical>
        {mentors.map(m => (
          <AssigneeChip
            assignment={m}
            key={m.mentor_id}
            _id={m.mentor_id}
            display_name={m.mentor_display_name}
            link={`/mentor?id=${m.mentor_id}`}
            remove={() => assignments.removeAssignment.run(m)}
            restore={() => assignments.restoreAssignment.run(m)}
            />
        ))}
      </Buttons>

      <Buttons>
        <Button
          size="small"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            assignments.createAssignment.startEditing({ student_id: personId });
            assignments.mentors.ensureLoaded();
          }}>
          <FormattedMessage id="metamentor.student.mentors.add" />
        </Button>

        <OccupyFreeSpace />
        <Button
          size="small"
          onClick={() => {
            personAssignments.openFor(personKind, personAssignments.selected ? null : (personId || null));
          }}>
          <FormattedMessage id={personAssignments.selected ? "metamentor.student.mentors.show_current_only" : "metamentor.student.mentors.show_history"} />
        </Button>
      </Buttons>
    </MentorAssignmentPanel>
  );
}



export const StudentAssignmentBlock = (props: Props) => {
  const { assignments, personId, personKind } = props;
  const { personAssignments } = assignments;

  const mentors = personAssignments.selected
    ? personAssignments.data
    : assignments.assignmentsByMentor[personId || ""] || [];

  const isAssignmentsLoading = (
    assignments.allAssignments.isLoading
    || (!!personAssignments.selected && personAssignments.isLoading));

  return (
    <MentorAssignmentPanel>
      <Typography variant="caption"><FormattedMessage id="metamentor.mentor.students.label" /></Typography>
      {isAssignmentsLoading &&
        <LoadingIndicator sizeVariant="s" />}
      {!mentors.length && !isAssignmentsLoading &&
        <Typography><FormattedMessage id="metamentor.mentor.students.not_assigned" /></Typography>}

      <Buttons vertical>
        {mentors.map(m => (
          <AssigneeChip
            assignment={m}
            key={m.student_id}
            _id={m.student_id}
            display_name={m.student_display_name}
            link={`/student?id=${m.student_id}`}
            remove={() => assignments.removeAssignment.run(m)}
            restore={() => assignments.restoreAssignment.run(m)}
            />
        ))}
      </Buttons>

      <Buttons>
        <Button
          size="small"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            assignments.createAssignment.startEditing({ mentor_id: personId });
            assignments.students.ensureLoaded();
          }}>
          <FormattedMessage id="metamentor.mentor.students.add" />
        </Button>

        <OccupyFreeSpace />
        <Button
          size="small"
          onClick={() => {
            personAssignments.openFor(personKind, personAssignments.selected ? null : (personId || null));
          }}>
          <FormattedMessage id={personAssignments.selected ? "metamentor.mentor.students.show_current_only" : "metamentor.mentor.students.show_history"} />
        </Button>
      </Buttons>
    </MentorAssignmentPanel>
  );
}
