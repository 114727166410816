import { useSettings } from '../../toolympus/api/useSettings';

export interface MMSettings {
  student_registration_allowed?: boolean | null;
  student_approval_mode?: string | null;
  mentor_registration_allowed?: boolean | null;
  mentor_approval_mode?: string | null;
  matching_mode?: string | null;
}

export const DefaultSettings: MMSettings = {

}

export const MatchingMode = {
  organizer: "organizer",
}

export const useSettingsEdit = () => {
  return useSettings<MMSettings>("/api/settings", DefaultSettings);
}
