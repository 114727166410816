import { Avatar, Chip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Assignment } from './useStudentsMentorsAssignments';
import { useFormats } from '../../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { Close, Restore } from '@mui/icons-material';

interface Props {
  _id: string;
  display_name: string;
  link?: string;
  assignment: Assignment;
  remove?: () => void;
  restore?: () => void;
}

export const AssigneeChip = (props: Props) => {
  const { assignment } = props;
  const { formatDate } = useFormats();

  const chip = (
    <Chip
      avatar={<Avatar src={`/api/profile/avatar/${props._id}`} />}
      label={assignment.is_terminated && assignment.terminated_at
        ? <FormattedMessage id="metamentor.common.name_since_to" values={{ name: props.display_name, since: formatDate(assignment.created_at), to: formatDate(assignment.terminated_at) }} />
        : <FormattedMessage id="metamentor.common.name_since" values={{ name: props.display_name, since: formatDate(assignment.created_at) }} />}
      onDelete={!assignment.is_terminated
          ? props.remove ? (e => { e.stopPropagation(); e.preventDefault(); props.remove && props.remove(); }) : undefined
          : props.restore ? (e => { e.stopPropagation(); e.preventDefault(); props.restore && props.restore(); }) : undefined}
      deleteIcon={!assignment.is_terminated
        ? <Close />
        : <Restore />}
      variant={assignment.is_terminated ? "filled" : "filled"}
      color={assignment.is_terminated ? "default" : "secondary"}
      />);

  return props.link
    ? <Link to={props.link} target="_blank">{chip}</Link>
    : chip;
}
