import { useMemo, useState } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { apiFetch } from "../../../toolympus/api/core";
import { Mentor } from "../Mentors/useMentorsList";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useIntl } from "react-intl";
import { Student } from "./useStudentsList";

export interface Assignment {
  _id: string;
  student_id: string;
  mentor_id: string;
  student_display_name: string;
  mentor_display_name: string;
  is_terminated?: boolean;
  terminated_at?: string;
  created_at?: string;
}

export const AssignmentApiPath = "/api/assignment/organizer";

export const useStudentsMentorsAssignments = () => {
  const allAssignments = useLoadedData<Assignment[]>(AssignmentApiPath, []);

  const [assignmentsByStudent, assignmentsByMentor] = useMemo(() => {
    const byStudent: Record<string,Assignment[]> = {};
    const byMentor: Record<string,Assignment[]> = {};
    allAssignments.data.forEach(a => {
      if(!byStudent[a.student_id]) {
        byStudent[a.student_id] = [];
      }
      byStudent[a.student_id].push(a);
      if(!byMentor[a.mentor_id]) {
        byMentor[a.mentor_id] = [];
      }
      byMentor[a.mentor_id].push(a);
    });

    return [byStudent,byMentor];
  }, [allAssignments.data]);

  const createAssignment = useEditItem2<Partial<Assignment>>({
    save: (item) => {
      return apiFetch<Assignment>(AssignmentApiPath, "post", item)
        .then(x => {
          allAssignments.reload();
          return x;
        })
    }
  });

  const [isMentorLoadRequested,setIsMentorLoadRequested] = useState<boolean>(false);
  const mentors = useLoadedData<Mentor[]>("/api/mentor/manage?unassignable=exclude", [], isMentorLoadRequested);

  const [isStudentLoadRequested,setIsStudentLoadRequested] = useState<boolean>(false);
  const students = useLoadedData<Student[]>("/api/student/manage?unassignable=exclude", [], isStudentLoadRequested);

  const { formatMessage } = useIntl();

  const personAssignments = useFullPersonAssignmentsList();

  const removeAssignment = useItemActionWithConfirmation<{ _id: string}, {}>(
    a => {
      return apiFetch<{}>(`${AssignmentApiPath}/${a._id}/terminate`, "post")
        .then(() => {
          allAssignments.reload();
          if(personAssignments.selected) {
            personAssignments.reload();
          }
          return {};
        })
    },
    { title: formatMessage({ id: "metamentor.common.assignment.remove" }), confirmationLabel: formatMessage({ id: "metamentor.common.assignment.remove_button" }) }
  );

  const restoreAssignment = useItemActionWithConfirmation<{ _id: string}, {}>(
    a => {
      return apiFetch<{}>(`${AssignmentApiPath}/${a._id}/unterminate`, "post")
        .then(() => {
          allAssignments.reload();
          if(personAssignments.selected) {
            personAssignments.reload();
          }
          return {};
        })
    },
    { title: formatMessage({ id: "metamentor.common.assignment.restore" }), confirmationLabel: formatMessage({ id: "metamentor.common.assignment.restore_button" }) }
  );

  return {
    allAssignments,
    assignmentsByStudent,
    assignmentsByMentor,
    createAssignment,
    removeAssignment,
    restoreAssignment,

    personAssignments,

    mentors: {
      ...mentors,
      ensureLoaded: () => setIsMentorLoadRequested(true),
    },
    students: {
      ...students,
      ensureLoaded: () => setIsStudentLoadRequested(true),
    },
  }
}

export type AssignmentsData = ReturnType<typeof useStudentsMentorsAssignments>;


export const useFullPersonAssignmentsList = (defaultId: string | null = null) => {
  const [selected,setSelected] = useState<{ kind: "student" | "mentor", id: string } | null>(null);
  const data = useLoadedData<Assignment[]>(`/api/assignment/organizer/${selected?.kind}/${selected?.id}?include-terminated=true`, [], !!selected);

  return {
    ...data,
    selected,
    openFor: (kind: "student" | "mentor", id: string | null) => setSelected(id ? { kind, id } : null),
    data: selected ? data.data : [],
  }
}

export type PersonAssignmentsData = ReturnType<typeof useFullPersonAssignmentsList>;
