import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const ThemeSettings = {
  colors: {
    primary: "#300C6A",
    secondary: "#9CE37D",
    aux1: "#78E3FD",
    aux2: "#D1F5FF",
    aux3: "#EEF8FF",
  }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: ThemeSettings.colors.primary,
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
        success: {
          main: ThemeSettings.colors.secondary,
        },
        info: {
          main: ThemeSettings.colors.aux1,
        },
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
