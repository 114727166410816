import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { ProfileConfig, useProfileConfigurationControls } from './useProfileConfiguration';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { Student } from './useStudentsList';
import { Dialog, FormGrid, LoadingIndicator, useCopyText } from '../../../toolympus/components/primitives';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields, MaybeFieldDefinition } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { IconButton, Typography } from '@mui/material';
import { GetAppOutlined } from '@mui/icons-material';
import { useDownloadFile } from '../../../toolympus/hooks/useDownloadFile';
import { FormControlBaseProps } from '../../../toolympus/components/schemed/FormControlProps';
import { ApprovalControl } from './ApprovalControl';
import { AssignmentsData } from './useStudentsMentorsAssignments';
import { MentorSelectionDialog } from '../Mentors/MentorSelectionDialog';
import { MentorAssignmentBlock } from './MentorAssignmentBlock';
import { TopicsFormDisplay } from './TopicsDisplay';

const AvatarSizes = {
  large: 150,
  medium: 50,
  small: 24,
}

export const Avatar = styled.img<{ size?: "large" | "medium" | "small" }>`
  width: ${props => AvatarSizes[props.size || "large"]}px;
  height: ${props => AvatarSizes[props.size || "large"]}px;
  border-radius: ${props => AvatarSizes[props.size || "large"] / 2}px;
  box-shadow: 0 0 ${props => !props.size || props.size === "large" ? 10 : 8}px -5px ${props => props.theme.palette.grey[800]};
`;




interface Props {
  data: EditItemProps<Student>;
  schema: Schema;
  profileConfiguration: ProfileConfig;
  assignments: AssignmentsData;
}

interface DownloadFileProps {
  download: (fileId: string) => void;
  isDownloading: boolean;
  fileId: string | null | undefined;
  label: ReactNode;
}

export const DownloadFileControl = (props: DownloadFileProps) => {
  return <div>
    <Typography variant="caption" color="textSecondary" component="p">{props.label}</Typography>
    {!props.fileId
      ? <Typography>-</Typography>
      : props.isDownloading
        ? <LoadingIndicator sizeVariant="s" color="primary" />
        : <IconButton size="small" color="primary" onClick={() => props.download(props.fileId || "")}>
      <GetAppOutlined />
    </IconButton>}
  </div>
}

export const StudentReviewDialog = (props: Props) => {
  const { data, assignments } = props;
  const { schema: schemaFields, fields, fileFields, topicsField } = useProfileConfigurationControls(props.profileConfiguration);
  const copyText = useCopyText();

  const downloadFile = useDownloadFile("");

  const fullFields: MaybeFieldDefinition[] = useMemo(() => {

    const FileControl = (p: FormControlBaseProps) => {
      return (
        <DownloadFileControl
          fileId={p.row[p.field]}
          download={fileId => !downloadFile.isLoading && downloadFile.download({ apiPath: `/api/student/document/${fileId}` })}
          isDownloading={downloadFile.isLoading}
          label={(p.schema || {}).label}
          />
      )
    }

    return fields.map(f => {
      const [fid,options] = f || [];
      if(!!fid && fileFields.includes(fid)) {
        return [
          fid,
          {
            ...(options || {}),
            control: FileControl,
          }
        ];
      } else if(!!fid && fid === topicsField) {
        return [
          fid,
          {
            ...(options || {}),
            control: TopicsFormDisplay,
          }
        ]
      } else {
        return f;
      }
    });
  }, [fields, fileFields, topicsField, downloadFile]);


  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={<FormattedMessage id="metamentor.student.profile.title" />}>
      {!!data.item &&
        <FormGrid columns="1fr">
          <FormGrid noMargin columns="1fr 150px">
            <FormGrid noMargin columns="1fr">
              <FormControlsForFields
                schema={props.schema}
                data={data.item}
                onChange={() => {}}
                fields={[
                  ["email", {
                    label: <FormattedMessage id="metamentor.student.profile.email" />,
                    readOnly: true,
                    controlProps: {
                      onClick: (e: any) => { e.stopPropagation(); e.preventDefault(); copyText(data.item?.email || ""); },
                    },
                  }],
                ]}
                />

                <ApprovalControl
                  status={data.item?.approval_status}
                  setStatus={v => data.save({ approval_status: v })}
                  isLoading={data.isLoading}
                  />

            </FormGrid>

            <Avatar src={`/api/profile/avatar/${data.item._id}`} />
          </FormGrid>
          
          
          <MentorAssignmentBlock
            assignments={assignments}
            personId={data.item._id || ""}
            personKind="student"
            />


          <FormControlsForFields
            schema={schemaFields}
            readOnly
            data={data.item}
            onChange={() => {}}
            fields={fullFields}
            />
        </FormGrid>}

        <MentorSelectionDialog
          mentors={assignments.mentors}
          assignment={assignments.createAssignment}
          assignments={assignments}
          selectMentor={m => { assignments.createAssignment.save({ mentor_id: m._id })}}
          />
    </Dialog>
  );
}
