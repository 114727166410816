import React, { useMemo } from 'react';
import { AutoexpanderDefault, ButtonOptionPicker, Buttons, Form, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, useDialogState } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { MaybeFieldDefinition, TableForFields } from '../../../toolympus/components/schemed';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { GetAppOutlined } from '@mui/icons-material';
import { useDownloadFile } from '../../../toolympus/hooks/useDownloadFile';
import { Mentor, useMentorsList } from './useMentorsList';
import { ProfileConfigurationDialog } from '../Students/ProfileConfigurationDialog';
import { MentorReviewDialog } from './MentorReviewDialog';
import { ApprovalFilterControl } from '../Students/ApprovalFilterControl';
import { useOpenByIdFromUrl } from '../../../toolympus/hooks/useOpenByIdFromUrl';
import { FormattedMessage } from 'react-intl';
import { getTopicsField } from '../Students/StudentsListPage';

interface Props {
  
}

export const MentorByStudentsFilterButtons = (props: { filter: string, setFilter: (v: string) => void }) => {
  return (
    <ButtonOptionPicker
      options={[
        [<FormattedMessage id="metamentor.mentor.students.students_filter.all" />,"all"],
        [<FormattedMessage id="metamentor.mentor.students.students_filter.student_yes" />,"student_yes"],
        [<FormattedMessage id="metamentor.mentor.students.students_filter.student_no" />,"student_no"],
      ]}
      selected={props.filter}
      setSelected={props.setFilter}
      />
  )
}

export const MentorsListPage = (props: Props) => {
  const data = useMentorsList();
  const profileConfigDialog = useDialogState();
  const [fields, fileFields] = useMemo(() => {
    const fields = [
      ["email"],
      ["display_name"],
      ["approval_status", { labelIdPrefix: "metamentor.common.approval.status" }],
      ...data.profileConfiguration.data.fields.filter(f => f.fieldtype !== "text_multiline").map(f => [f._id] as MaybeFieldDefinition),
      getTopicsField(data.profileConfiguration.data),
      ["students_assigned_nbr"],
      ["is_dont_assign_new_students", {}],
      ["created_at", { utcToLocal: true }],
    ];

    const fileFields = data.profileConfiguration.data.fields.filter(f => f.fieldtype === "file").map(f => f._id);

    return [fields as MaybeFieldDefinition[],fileFields];
  }, [data.profileConfiguration.data]);

  const downloadFile = useDownloadFile("");


  useOpenByIdFromUrl<Mentor>("id", "_id", data.data, x => data.review.startEditing(x), data.review.item?._id);

  return (<>
    <Form
      title="Менторы"
      headerItems={<>

        <SearchField
          filter={data.filters.textFilter.filter}
          setFilter={data.filters.textFilter.setFilter}
          noButton
          />
        
        <OccupyFreeSpace />

        <MentorByStudentsFilterButtons
          filter={data.filters.studentsFilter}
          setFilter={data.filters.setStudentsFilter}
          />
        
        <ApprovalFilterControl
          status={data.filters.statusFilter}
          setStatus={data.filters.setStatusFilter}
          />
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers={[
            "metamentor.core.mentors.mentors-entities/registration",
            "metamentor.core.mentors.mentors-entities/resend-email-confirmation",
            "metamentor.core.mentors.mentors-entities/stop-receiving-students",
            "metamentor.core.assignments.assignment-entities/registration",
          ]}
          />
        <Button size="small" color="primary" onClick={() => profileConfigDialog.open()}>конфигурация</Button>
      </>}>
      
      <TableForFields
        data={data.data}
        schema={data.schema}
        fields={fields}
        fieldElement={f => {
          if(fileFields.includes(f)) {
            return r => (r as any)[f]
              ? <Buttons>
                  {downloadFile.isLoading
                    ? <LoadingIndicator sizeVariant="s" color="primary" />
                    :  <IconButton
                          size="small"
                          color="primary"
                          onClick={() => !downloadFile.isLoading && downloadFile.download({ apiPath: `/api/mentor/document/${(r as any)[f]}` })}>
                        <GetAppOutlined />
                      </IconButton>}
                </Buttons>
              : <></>;
          }
          switch(f) {
            case "email":
            case "approval_status":
              return (r,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(r)}>{orig}</PseudoLink2>
            case "students_assigned_nbr":
              return r => <>{data.assignments.assignmentsByMentor[r._id || ""]?.length || ""}</>
          }
        }}
        autoexpander={AutoexpanderDefault}
        />
    </Form>

    <ProfileConfigurationDialog
      data={data.profileConfiguration}
      title="Конфигурация профиля ментора"
      {...profileConfigDialog}
      />

    <MentorReviewDialog
      data={data.review}
      schema={data.schema}
      profileConfiguration={data.profileConfiguration.data}
      assignments={data.assignments}
      />
  </>);
}
