import { useMemo, useState } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { Schema, mergeSchema, useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { schemaWithConfigurableFields, useStudentProfileConfiguration } from "./useProfileConfiguration";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { AssignmentsData, useStudentsMentorsAssignments } from "./useStudentsMentorsAssignments";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";

export interface Student {
  _id: string;
  email: string;
  display_name: string;
  info: Record<string, any>;
  approval_status?: string;
}


export const StudentExtraFieldsSchema: Schema = {
  avatar: { label: " " },
  display_name: { label_id: "metamentor.common.profile.display_name" },
  mentors_assigned_nbr: { label_id: "metamentor.student.profile.mentors_assigned_nbr"}
} 

export const filterByStatus = <T extends { approval_status?: string }>(list: T[], filter: string) => {
  return filter && filter !== "all" ? list.filter(i => i.approval_status === filter) : list;
}

export const useFilterStudentList = (mentors: Student[], assignments: AssignmentsData, excludeIds?: string[]) => {
  const textFilter = useTextFilter<Student>(s => `${s.email} ${s.display_name}`);
  const [statusFilter,setStatusFilter] = useState<string>("all");
  const [mentorsFilter,setMentorsFilter] = useState<string>("all");

  const filtered = useMemo(() => {
    const statusFiltered = filterByStatus(mentors, statusFilter);
    const studentsFiltered = mentorsFilter === "all"
      ? statusFiltered
      : mentorsFilter === "mentor_yes"
        ? statusFiltered.filter(s => !!assignments.assignmentsByStudent[s._id]?.length)
        : statusFiltered.filter(s => !assignments.assignmentsByStudent[s._id]?.length)

    const filteredPreFinal = textFilter.filterData(studentsFiltered);
    return excludeIds?.length
      ? filteredPreFinal.filter(s => !excludeIds.includes(s._id))
      : filteredPreFinal;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentors, textFilter.filter, statusFilter, mentorsFilter, assignments.assignmentsByStudent, excludeIds]);


  return {
    textFilter,
    statusFilter,
    setStatusFilter,
    mentorsFilter,
    setMentorsFilter,

    data: filtered,
  }
}

export const useStudentsList = () => {
  const profileConfiguration = useStudentProfileConfiguration();

  const students = useLoadedData<Student[]>("/api/student/manage", []);

  const { schema: schemaBase } = useSingleSchema("/api/student/manage/uiconfig");

  const schema = useMemo(
    () => schemaWithConfigurableFields(
      mergeSchema(schemaBase, StudentExtraFieldsSchema),
      profileConfiguration.data.fields,
      { cropTitles: true }),
    [schemaBase, profileConfiguration.data]);


  const assignments = useStudentsMentorsAssignments();
  

  const data = useMemo(() => {
    return students.data.map(s => {
      const copy = { ...s } as any;
      profileConfiguration.data.fields.forEach(f => {
        copy[f._id] = s.info[f._id];
      });
      return copy;
    });
  }, [students.data, profileConfiguration.data]);

  const filtered = useFilterStudentList(data, assignments);

  const review = useEditItem2<Student>({
    dontResetOnSave: true,
    getApiPath: item => `/api/student/manage/${item._id}`,
  });


  return {
    ...students,
    data: filtered.data,
    filters: filtered,

    profileConfiguration,
    schema,
    review: {
      ...review,
      startEditing: (item: Student) =>{
        review.startEditing(item);
        assignments.personAssignments.openFor("student", null);
      },
      save: (extraChanges?: Partial<Student>) => review.save(extraChanges).then(x => { students.reload(); return x; }),
    },

    assignments,
  }
}

