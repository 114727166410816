import React, { useMemo } from 'react';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { Dialog, FormGrid, useCopyText } from '../../../toolympus/components/primitives';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields, MaybeFieldDefinition } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { useDownloadFile } from '../../../toolympus/hooks/useDownloadFile';
import { FormControlBaseProps } from '../../../toolympus/components/schemed/FormControlProps';
import { Mentor } from './useMentorsList';
import { ProfileConfig, useProfileConfigurationControls } from '../Students/useProfileConfiguration';
import { Avatar, DownloadFileControl } from '../Students/StudentReviewDialog';
import { ApprovalControl } from '../Students/ApprovalControl';
import { StudentAssignmentBlock } from '../Students/MentorAssignmentBlock';
import { AssignmentsData } from '../Students/useStudentsMentorsAssignments';
import { StudentSelectionDialog } from '../Students/StudentSelectionDialog';
import { TopicsFormDisplay } from '../Students/TopicsDisplay';

interface Props {
  data: EditItemProps<Mentor>;
  schema: Schema;
  profileConfiguration: ProfileConfig;
  assignments: AssignmentsData;
}


export const MentorReviewDialog = (props: Props) => {
  const { data, assignments } = props;
  const { schema: schemaFields, fields, fileFields, topicsField } = useProfileConfigurationControls(props.profileConfiguration);
  const copyText = useCopyText();

  const downloadFile = useDownloadFile("");

  const fullFields: MaybeFieldDefinition[] = useMemo(() => {

    const FileControl = (p: FormControlBaseProps) => {
      return (
        <DownloadFileControl
          fileId={p.row[p.field]}
          download={fileId => !downloadFile.isLoading && downloadFile.download({ apiPath: `/api/mentor/document/${fileId}` })}
          isDownloading={downloadFile.isLoading}
          label={(p.schema || {}).label}
          />
      )
    }

    return fields.map(f => {
      const [fid,options] = f || [];
      if(!!fid && fileFields.includes(fid)) {
        return [
          fid,
          {
            ...(options || {}),
            control: FileControl,
          }
        ];
      } else if(!!fid && fid === topicsField) {
        return [
          fid,
          {
            ...(options || {}),
            control: TopicsFormDisplay,
          }
        ]
      } else {
        return f;
      }
    });
  }, [fields, fileFields, topicsField, downloadFile]);

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={<FormattedMessage id="metamentor.mentor.profile.title" />}>
      {!!data.item &&
        <FormGrid columns="1fr">
          <FormGrid noMargin columns="1fr 150px">
            <FormGrid noMargin columns="1fr">
              <FormControlsForFields
                schema={props.schema}
                data={data.item}
                onChange={() => {}}
                fields={[
                  ["email", {
                    label: <FormattedMessage id="metamentor.student.profile.email" />,
                    readOnly: true,
                    controlProps: {
                      onClick: (e: any) => { e.stopPropagation(); e.preventDefault(); copyText(data.item?.email || ""); },
                    },
                  }],
                  ["is_dont_assign_new_students"],
                ]}
                />

                <ApprovalControl
                  status={data.item?.approval_status}
                  setStatus={v => data.save({ approval_status: v })}
                  isLoading={data.isLoading}
                  />

                <div />

            </FormGrid>

            <Avatar src={`/api/profile/avatar/${data.item._id}`} />
          </FormGrid>


          <StudentAssignmentBlock
            assignments={assignments}
            personId={data.item._id || ""}
            personKind="mentor"
            />
          


          <FormControlsForFields
            schema={schemaFields}
            readOnly
            data={data.item}
            onChange={() => {}}
            fields={fullFields}
            />
        </FormGrid>}

        <StudentSelectionDialog
          students={assignments.students}
          assignment={assignments.createAssignment}
          assignments={assignments}
          selectStudent={s => { assignments.createAssignment.save({ student_id: s._id })}}
          />
    </Dialog>
  );
}
