import React, { useMemo } from 'react';
import { DisplayProps, FormControlBaseProps } from '../../../toolympus/components/schemed/FormControlProps';
import { DictionarySelectMulti, DictionarySelectMultiDisplay } from '../../../toolympus/components/schemed/Select';


export const TopicsDictionaryCode = "Topics";

export const TopicsDisplay = (props: DisplayProps) => {
  const [valueList,schema] = useMemo(() => {
    return [
      ((props.row || {})[props.field] || "").split("\n"),
      { ...props.schema, dictionary: TopicsDictionaryCode },
    ]
  }, [props.row, props.field, props.schema])
  return (
    <DictionarySelectMultiDisplay
      field={props.field}
      row={{ [props.field]: valueList }}
      schema={schema}
      />
  );
}

export const TopicsFormDisplay = (props: FormControlBaseProps) => {
  const [valueList,schema] = useMemo(() => {
    return [
      ((props.row || {})[props.field] || "").split("\n"),
      { ...props.schema, dictionary: TopicsDictionaryCode },
    ]
  }, [props.row, props.field, props.schema])
  return (
    <DictionarySelectMulti
      field={props.field}
      row={{ [props.field]: valueList }}
      schema={schema}
      onChange={() => {}}
      extraProps={{ readOnly: true }}
      />
  );
}
