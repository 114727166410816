import React from 'react';
import { ButtonOptionPicker } from '../../../toolympus/components/primitives';
import { FormattedMessage } from 'react-intl';

interface Props {
  status: string;
  setStatus: (v: string) => void;
}

export const ApprovalFilterControl = (props: Props) => {
  return (
    <ButtonOptionPicker
      options={[
        [<FormattedMessage id="metamentor.common.approval.status_filter.all" />,"all"],
        [<FormattedMessage id="metamentor.common.approval.status_filter.unknown" />,"unknown"],
        [<FormattedMessage id="metamentor.common.approval.status_filter.approved" />,"approved"],
        [<FormattedMessage id="metamentor.common.approval.status_filter.excluded" />,"excluded"],
      ]}
      selected={props.status}
      setSelected={props.setStatus}
      />
  );
}
