import React, { useMemo } from 'react';
import { LoadedData } from '../../../toolympus/hooks/useLoadedData';
import { Mentor, MentorExtraFieldsSchema, useFilterMentorList } from './useMentorsList';
import { AutoexpanderDefault, Buttons, Dialog, FormGrid, LoadingIndicator, SearchField } from '../../../toolympus/components/primitives';
import { FormattedMessage } from 'react-intl';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { Assignment, AssignmentsData } from '../Students/useStudentsMentorsAssignments';
import { TableForFields } from '../../../toolympus/components/schemed';
import { Avatar } from '../Students/StudentReviewDialog';
import { Button } from '@mui/material';
import { MentorByStudentsFilterButtons } from './MentorsListPage';

interface Props {
  assignment: EditItemProps<Partial<Assignment>>;
  mentors: LoadedData<Mentor[]>;
  assignments: AssignmentsData;
  selectMentor: (m: Mentor) => void;
}

export const MentorSelectionDialog = (props: Props) => {
  const assignedToThis = useMemo(() => {
    return props.assignment.item?.student_id
      ? (props.assignments.assignmentsByStudent[props.assignment.item?.student_id] || []).map(x => x.mentor_id)
      : [];
  }, [props.assignment.item?.student_id, props.assignments.assignmentsByStudent]);

  const filteredList = useFilterMentorList(props.mentors.data, props.assignments, assignedToThis);

  return (
    <Dialog
      isOpen={props.assignment.isEditing}
      close={() => props.assignment.cancel()}
      noSubmitOnEnter
      dialogTitle={<>
        <FormattedMessage id="metamentor.student.mentors.add_dialog_title" />
        {(props.mentors.isLoading || props.assignment.isLoading) && <LoadingIndicator sizeVariant="s" />}
      </>}>
      <FormGrid noMargin columns="1fr">
        <SearchField
          filter={filteredList.textFilter.filter}
          setFilter={filteredList.textFilter.setFilter}
          noButton
          fullWidth
          autoFocus
          doSearch={() => {
            if(filteredList.data.length === 1) {
              props.selectMentor(filteredList.data[0]);
            }
          }}
          />

        <Buttons>
          <MentorByStudentsFilterButtons
            filter={filteredList.studentsFilter}
            setFilter={filteredList.setStudentsFilter}
            />
        </Buttons>
      </FormGrid>

      <TableForFields
        data={filteredList.data}
        schema={MentorExtraFieldsSchema}
        fields={[
          ["avatar"],
          ["display_name"],
          ["students_assigned_nbr"],
          ["assign", { label: " " }]
        ]}
        autoexpander={AutoexpanderDefault}
        fieldElement={f => {
          switch(f) {
            case "avatar":
              return r => <Buttons><Avatar size="small" src={`/api/profile/avatar/${r._id}`} /></Buttons>;
            case "assign":
              return r => <Buttons><Button size="small" color="primary" onClick={() => props.selectMentor(r)}><FormattedMessage id="metamentor.student.mentors.add" /></Button></Buttons>;
            case "students_assigned_nbr":
              return r => <>{props.assignments.assignmentsByMentor[r._id]?.length || ""}</>
          }
        }}
        />
    </Dialog>
  );
}
