import React from "react";
import { PersonOutlined, PrintOutlined, Settings, SettingsOutlined } from "@mui/icons-material";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { CMS } from "./components/Admin/CMS";
import { SettingsPage } from "./components/Admin/SettingsPage";
import { MentorsListPage, StudentsListPage } from "./components/Participants";

const InnerRoutes: MenuRoute[] = [
  {
    path: "/student",
    title: "Студенты",
    icon: <PersonOutlined />,
    component: StudentsListPage,
  },
  {
    path: "/mentor",
    title: "Менторы",
    icon: <PersonOutlined />,
    component: MentorsListPage,
  },

    { 
        path: '/system',
        title: 'Конфигурация',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                users={{ allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: false }}
                dictionaries={{ apiPath: "/api/settings/dictionaries"}}
                messages={{}}
                robud={{}}
                noCloseButton
                extraItems={[
                  {
                    key: "cms",
                    label: "CMS",
                    icon: <PrintOutlined />,
                    items: [{ key: "documentation", label: "", component: CMS }]
                  },
                  
                  {
                    key: "settings",
                    label: "Настройки",
                    icon: <SettingsOutlined />,
                    items: [{ key: "settings", label: "", component: SettingsPage }]
                  },
                ]}
            />),
    },
];

export default InnerRoutes;