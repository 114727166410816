import React from 'react';
import { Buttons, LoadingIndicator, Tooltip } from '../../../toolympus/components/primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Check, Close, QuestionMarkOutlined } from '@mui/icons-material';

interface Props {
  status?: string;
  setStatus: (v: string) => void;
  isLoading?: boolean;
}

const StatusColor: any = {
  unknown: undefined,
  approved: "success",
  excluded: "error",
}

const StatusIcon: any = {
  unknown: <QuestionMarkOutlined />,
  approved: <Check />,
  excluded: <Close />,
}

export const ApprovalControl = (props: Props) => {
  const status = props.status || "unknown";

  const statuses = ["unknown", "approved", "excluded"].filter(s => s !== status);

  return (<>
    <Typography variant="caption" color="textSecondary"><FormattedMessage id="metamentor.common.approval.status.label" /></Typography>
    <Buttons>
      {props.isLoading && <LoadingIndicator />}

      {!props.isLoading && <>
        <Button variant="contained" size="small" color={StatusColor[status]} startIcon={StatusIcon[status]}>
          <FormattedMessage id={`metamentor.common.approval.status.${status}`} />
        </Button>

        {statuses.map(s => (
          <Tooltip text_id={`metamentor.common.approval.status.${s}`}>
            <IconButton size="small" color={StatusColor[s]} onClick={() => !props.isLoading && props.setStatus(s)}>
              {StatusIcon[s]}
            </IconButton>
          </Tooltip>
        ))}
      </>}
    </Buttons>
  </>);
}
