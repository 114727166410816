import React, { ReactNode } from 'react';
import { ProfileConfigurationData } from './useProfileConfiguration';
import { Dialog, DialogState, FormGrid } from '../../../toolympus/components/primitives';
import { Button, Divider, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CustomFieldType, FormFieldsEditor } from '../../../toolympus/components/ConfigurableForms/FormFieldsEditor';
import { useEditFormFieldsBasic } from '../../../toolympus/components/ConfigurableForms/useEditConfigurableForm';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';

interface Props extends Omit<DialogState, "open"> {
  data: ProfileConfigurationData;
  title: ReactNode;
  hint?: ReactNode;
}

const ProfileConfigSchema: Schema = {
  display_name_format: { label_id: "metamentor.common.profile_config.display_name_format" },
  public_profile_fields: { label_id: "metamentor.common.profile_config.public_profile_fields" },
  public_profile_short_fields: { label_id: "metamentor.common.profile_config.public_profile_short_fields" },
}

export const TopicsFieldType = "topics";

const CustomFieldTypes: CustomFieldType[] = [
  { value: TopicsFieldType, },
]

export const ProfileConfigurationDialog = (props: Props) => {
  const fieldsData = useEditFormFieldsBasic(props.data);
  return (
    <Dialog
      dialogTitle={props.title}
      closeButtonTop
      isOpen={props.isOpen}
      close={() => { props.close(); props.data.reload(); }}
      maxWidth="md"
      actions={
        <Button color="primary" variant="contained" onClick={() => { props.data.save().then(() => props.close()); }}>
          <FormattedMessage id="common.save" />
        </Button>
      }>
      <FormGrid columns="1fr">
        {props.hint}

        <FormControlsForFields
          fields={[
            ["display_name_format"],
            ["public_profile_fields"],
            ["public_profile_short_fields"],
          ]}
          schema={ProfileConfigSchema}
          data={props.data.data}
          onChange={(o,c) => props.data.update(c)}
          />

        <div />

        <Divider />

        <Typography><FormattedMessage id="metamentor.common.profile_config.fields" /></Typography>

        <FormFieldsEditor
          data={fieldsData}
          customFieldTypes={CustomFieldTypes}
          noRegexes
          allowSubtypes
          />

      </FormGrid>
    </Dialog>
  );
}
