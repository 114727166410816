import { useMemo } from "react";
import { useCrudItem } from "../../../toolympus/api/useSimpleCrud"
import { FormField } from "../../../toolympus/components/ConfigurableForms/types";
import { Schema } from "../../../toolympus/hooks/useSchema";
import { fieldtypeToSchemaType } from "../../../toolympus/components/ConfigurableForms/useConfigurableFormSubmissions";
import { cropText } from "../../../toolympus/components/primitives";
import { MaybeFieldDefinition } from "../../../toolympus/components/schemed";
import { TopicsFieldType } from "./ProfileConfigurationDialog";

export interface ProfileConfig {
  code: string;
  fields: FormField[];
  display_name_format: string;
  public_profile_fields: string;
  public_profile_short_fields: string;
}

export const useProfileConfiguration = (apiPath: string) => {
  const data = useCrudItem<ProfileConfig>(apiPath, {
    defaultValue: {
      code: "",
      fields: [],
      display_name_format: "",
      public_profile_fields: "",
      public_profile_short_fields: "",
    },
  });

  return {
    ...data,
  }
}

export type ProfileConfigurationData = ReturnType<typeof useProfileConfiguration>;


export const useStudentProfileConfiguration = () => {
  return useProfileConfiguration("/api/student/config");
}



export const schemaWithConfigurableFields = (baseSchema: Schema, fields: ProfileConfig["fields"], cfg?: { cropTitles?: boolean }) => {
  return fields.reduce<Schema>(
    (r,f) => {
        r[f._id] = {
            type: fieldtypeToSchemaType(f.fieldtype),
            label: cfg?.cropTitles ? cropText(f.title, 50) : f.title,
        }
        return r;
    },
    { ...baseSchema }
  );
}

export const useProfileConfigurationControls = (config: ProfileConfig) => {
  const [schema, fields, fileFields, topicsField] = useMemo(() => {
    const schema = schemaWithConfigurableFields({}, config.fields || []);

    const fields: MaybeFieldDefinition[] = config.fields.map(f => [f._id]);
    const fileFields = config.fields.filter(f => f.fieldtype === "file").map(f => f._id);
    const topicsField = config.fields.find(f => f.fieldtype === TopicsFieldType)?._id;

    return [
      schema,
      fields,
      fileFields,
      topicsField,
    ]
  }, [config]);

  return {
    schema,
    fields,
    fileFields,
    topicsField,
  }
}

