export const MMRuMessages = {
  metamentor: {
    settings: {
      student_registration_allowed: "Регистрация студентов разрешена",
      student_approval_mode: "Режим утверждения студентов",
      mentor_registration_allowed: "Регистрация менторов разрешена",
      mentor_approval_mode: "Режим утверждения студентов",
      matching_mode: "Режим матчинга студентов и менторов",
    },

    common: {
      profile_config: {
        display_name_format: "Формат отображаемого имени",
        public_profile_fields: "Публичный профиль (поля)",
        public_profile_short_fields: "Краткий публичный профиль (поля)",
        fields: "Поля профиля",
      },

      name_since: "{name} ({since})",
      name_since_to: "{name} ({since} - {to})",

      profile: {
        display_name: "Имя",
      },

      approval: {
        status: {
          label: "Статус",
          unknown: "Новый",
          approved: "Утвержден",
          excluded: "Исключен",
        },

        status_filter: {
          all: "Все",
          unknown: "Новые",
          approved: "Утвержденные",
          excluded: "Исключенные",
        },
      },

      assignment: {
        remove: "Удалить назначение",
        remove_button: "Удалить",
        restore: "Восстановить назначение",
        restore_button: "Восстановить",
      },
    },

    student: {
      profile: {
        title: "Профиль студента",
        email: "Email",
        mentors_assigned_nbr: "Менторы",
      },

      mentors: {
        label: "Менторы",
        not_assigned: "Ментор пока не выбран",
        add: "Выбрать",
        add_dialog_title: "Выберите ментора",
        show_history: "Показать всех",
        show_current_only: "Только активные",

        mentors_filter: {
          all: "Все",
          mentor_yes: "С менторами",
          mentor_no: "Без менторов",
        },
      },
    },

    mentor: {
      profile: {
        title: "Профиль ментора",
        students_assigned_nbr: "Студентов",
      },

      students: {
        label: "Студенты",
        not_assigned: "Студенты пока не назначены",
        add: "Выбрать",
        add_dialog_title: "Выберите студента",
        show_history: "Показать всех",
        show_current_only: "Только активные",

        students_filter: {
          all: "Все",
          student_yes: "Со студентами",
          student_no: "Без студентов",
        },
      }
    },
  },

  forms: {
    field: {
      types: {
        topics: "Темы",
      },
    },
  },
}
