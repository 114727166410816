import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import styled from '@emotion/styled';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';

const DrawerPaper = styled(DrawerPaperView)`
  & .MuiListItemIcon-root {
    min-width: 0;
  }

  & .logo {
    padding: 14px;
  }
`;


const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <AppWrapper>
            <DictionariesProvider apiPath="/api/settings/dictionaries">
              <Sidebar
                  top={<Logo />}
                  menu={<Menu menuItems={menuItems} iconsOnly />}
                  bottom={bottom}
                  open={true}
                  onClose={() => { }}
                  width={60}
                  drawerPaperComponent={DrawerPaper}
                  drawerPaperProps={{ shadow: true }}
              />
              <AppContentSimple fit100Height>
                  <Routes routes={routes} notFoundRedirect="/student" />
              </AppContentSimple>
            </DictionariesProvider>
          </AppWrapper>
        </UserContext.Provider>
    );
}

export default AppRouter;

