import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { MMRuMessages } from './mmRuMessages';
import { ApiConfgurationProvider } from './toolympus/components/ApiConfguration';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';
import { ActionsConfigProvider } from './toolympus/components/Actions/ActionsConfigProvider';

interface Props {
    children?: ReactNode;
}

const Formats = {
  date: "D.MM.yyyy",
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="ru" defaultLocale="ru" messages={MMRuMessages}>
                    <FormatsProvider formats={Formats}>
                      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                        <ApiConfgurationProvider
                          actionsApiPath="/api/actions"
                          emailTemplatesApiPath="/api/emails">
                          <ConfirmationDialogProvider>
                            <ActionsConfigProvider>
                              <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                                {props.children}
                              </DatesLocalizationProvider>
                            </ActionsConfigProvider>
                          </ConfirmationDialogProvider>
                        </ApiConfgurationProvider>
                      </SnackbarProvider>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
