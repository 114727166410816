import React from 'react';
import { useSettingsEdit } from './useSettings';
import { Form, FormGrid, SaveButton, useSaveable } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props {
  
}

export const SettingsPage = (props: Props) => {
  const data = useSettingsEdit();

  useSaveable(data);

  return (
    <Form
      title="Settings"
      fitFullHeight
      headerItems={<>
        {data.hasChanges && <SaveButton action={() => data.save()} />}
      </>}>
      <FormGrid columns="1fr 1fr" forceEvenColumns>
        <FormControlsForFields
          schema={data.schema}
          data={data.settings}
          onChange={(o,c) => data.update(c)}
          fields={[
            ["student_registration_allowed"],
            ["student_approval_mode"],
            ["mentor_registration_allowed"],
            ["mentor_approval_mode"],
            ["matching_mode"],
          ]}
          />
      </FormGrid>
    </Form>
  );
}
