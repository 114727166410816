import React, { useMemo } from 'react';
import { AutoexpanderDefault, ButtonOptionPicker, Buttons, Form, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, useDialogState } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { ProfileConfigurationDialog, TopicsFieldType } from './ProfileConfigurationDialog';
import { Student, useStudentsList } from './useStudentsList';
import { MaybeFieldDefinition, TableForFields } from '../../../toolympus/components/schemed';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';
import { GetAppOutlined } from '@mui/icons-material';
import { useDownloadFile } from '../../../toolympus/hooks/useDownloadFile';
import { StudentReviewDialog } from './StudentReviewDialog';
import { ApprovalFilterControl } from './ApprovalFilterControl';
import { MatchingMode, useSettingsEdit } from '../../Admin/useSettings';
import { AssigneeChip } from './Assignee';
import { useOpenByIdFromUrl } from '../../../toolympus/hooks/useOpenByIdFromUrl';
import { FormattedMessage } from 'react-intl';
import { TopicsDisplay } from './TopicsDisplay';
import { ProfileConfig } from './useProfileConfiguration';

export const StudentByMentorsFilterButtons = (props: { filter: string, setFilter: (v: string) => void }) => {
  return (
    <ButtonOptionPicker
      options={[
        [<FormattedMessage id="metamentor.student.mentors.mentors_filter.all" />,"all"],
        [<FormattedMessage id="metamentor.student.mentors.mentors_filter.mentor_yes" />,"mentor_yes"],
        [<FormattedMessage id="metamentor.student.mentors.mentors_filter.mentor_no" />,"mentor_no"],
      ]}
      selected={props.filter}
      setSelected={props.setFilter}
      />
  )
}

export const getTopicsField = (profileConfig: ProfileConfig): MaybeFieldDefinition => {
  const topicsField = profileConfig.fields.find(f => f.fieldtype === TopicsFieldType);
  return topicsField ? [topicsField._id, { tableControl: TopicsDisplay, }] : null;
}


interface Props {
  
}

export const StudentsListPage = (props: Props) => {
  const data = useStudentsList();
  const profileConfigDialog = useDialogState();

  const settings = useSettingsEdit();

  const [fields, fileFields] = useMemo(() => {
    const fields: MaybeFieldDefinition[] = [
      ["email"],
      ["display_name"],
      ["approval_status", { labelIdPrefix: "metamentor.common.approval.status" }],
      settings.settings.matching_mode === MatchingMode.organizer ? ["is_ready_for_matching"] : null,
      ["mentors_assigned_nbr"],
      // ...data.profileConfiguration.data.fields.map(f => [f._id] as MaybeFieldDefinition),
      getTopicsField(data.profileConfiguration.data),
      ["created_at", { utcToLocal: true }],
    ];

    const fileFields = data.profileConfiguration.data.fields.filter(f => f.fieldtype === "file").map(f => f._id);

    return [fields as MaybeFieldDefinition[],fileFields];
  }, [data.profileConfiguration.data, settings.settings]);

  const downloadFile = useDownloadFile("");

  useOpenByIdFromUrl<Student>("id", "_id", data.data, x => data.review.startEditing(x), data.review.item?._id);

  return (<>
    <Form
      title="Студенты"
      headerItems={<>

        <SearchField
          filter={data.filters.textFilter.filter}
          setFilter={data.filters.textFilter.setFilter}
          noButton
          />
        
        <OccupyFreeSpace />

        <StudentByMentorsFilterButtons
          filter={data.filters.mentorsFilter}
          setFilter={data.filters.setMentorsFilter}
          />
        
        <ApprovalFilterControl
          status={data.filters.statusFilter}
          setStatus={data.filters.setStatusFilter}
          />
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers={[
            "metamentor.core.students.students-entities/registration",
            "metamentor.core.students.students-entities/resend-email-confirmation",
            "metamentor.core.assignments.assignment-entities/registration",
          ]}
          />
        <Button size="small" color="primary" onClick={() => profileConfigDialog.open()}>конфигурация</Button>
      </>}>
      
      <TableForFields
        data={data.data}
        schema={data.schema}
        fields={fields}
        fieldElement={f => {
          if(fileFields.includes(f)) {
            return r => (r as any)[f]
              ? <Buttons>
                  {downloadFile.isLoading
                    ? <LoadingIndicator sizeVariant="s" color="primary" />
                    :  <IconButton
                          size="small"
                          color="primary"
                          onClick={() => !downloadFile.isLoading && downloadFile.download({ apiPath: `/api/student/document/${(r as any)[f]}` })}>
                        <GetAppOutlined />
                      </IconButton>}
                </Buttons>
              : <></>;
          }
          switch(f) {
            case "email":
            case "approval_status":
              return (r,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(r)}>{orig}</PseudoLink2>
            case "mentors_assigned_nbr":
              return r => (
                <Buttons vertical onClick={() => data.review.startEditing(r)}>
                  {(data.assignments.assignmentsByStudent[r._id || ""] || []).map(m => (
                    <AssigneeChip
                      assignment={m}
                      _id={m.mentor_id}
                      display_name={m.mentor_display_name}
                      />
                  ))}
                </Buttons>)
          }
        }}
        autoexpander={AutoexpanderDefault}
        />
    </Form>

    <ProfileConfigurationDialog
      data={data.profileConfiguration}
      title="Конфигурация профиля студента"
      {...profileConfigDialog}
      />

    <StudentReviewDialog
      data={data.review}
      schema={data.schema}
      profileConfiguration={data.profileConfiguration.data}
      assignments={data.assignments}
      />
  </>);
}
