import { useMemo, useState } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { Schema, mergeSchema, useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { schemaWithConfigurableFields, useProfileConfiguration } from "../Students/useProfileConfiguration";
import { filterByStatus } from "../Students/useStudentsList";
import { AssignmentsData, useStudentsMentorsAssignments } from "../Students/useStudentsMentorsAssignments";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";

export interface Mentor {
  _id: string;
  email: string;
  display_name?: string;
  info: Record<string, any>;
  approval_status?: string;
}

export const MentorExtraFieldsSchema: Schema = {
  avatar: { label: " " },
  display_name: { label_id: "metamentor.common.profile.display_name" },
  students_assigned_nbr: { label_id: "metamentor.mentor.profile.students_assigned_nbr"}
} 

export const useFilterMentorList = (mentors: Mentor[], assignments: AssignmentsData, excludeIds?: string[]) => {
  const textFilter = useTextFilter<Mentor>(m => `${m.email} ${m.display_name}`);
  const [statusFilter,setStatusFilter] = useState<string>("all");
  const [studentsFilter,setStudentsFilter] = useState<string>("all");

  const filtered = useMemo(() => {
    const statusFiltered = filterByStatus(mentors, statusFilter);
    const studentsFiltered = studentsFilter === "all"
      ? statusFiltered
      : studentsFilter === "student_yes"
        ? statusFiltered.filter(m => !!assignments.assignmentsByMentor[m._id]?.length)
        : statusFiltered.filter(m => !assignments.assignmentsByMentor[m._id]?.length)

    const filteredPreFinal = textFilter.filterData(studentsFiltered);
    return excludeIds?.length
      ? filteredPreFinal.filter(m => !excludeIds.includes(m._id))
      : filteredPreFinal;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentors, textFilter.filter, statusFilter, studentsFilter, assignments.assignmentsByStudent, excludeIds]);


  return {
    textFilter,
    statusFilter,
    setStatusFilter,
    studentsFilter,
    setStudentsFilter,

    data: filtered,
  }
}

export const useMentorProfileConfiguration = () => {
  return useProfileConfiguration("/api/mentor/config");
}

export const useMentorsList = () => {
  const profileConfiguration = useMentorProfileConfiguration();

  const mentors = useLoadedData<Mentor[]>("/api/mentor/manage", []);

  const { schema: schemaBase } = useSingleSchema("/api/mentor/manage/uiconfig");

  const schema = useMemo(
    () => schemaWithConfigurableFields(
      mergeSchema(schemaBase, MentorExtraFieldsSchema),
      profileConfiguration.data.fields,
      { cropTitles: true }),
    [schemaBase, profileConfiguration.data]);


  const assignments = useStudentsMentorsAssignments();


  const data = useMemo(() => {
    return mentors.data.map(s => {
      const copy = { ...s } as any;
      profileConfiguration.data.fields.forEach(f => {
        copy[f._id] = s.info[f._id];
      });
      return copy;
    });
  }, [mentors.data, profileConfiguration.data]);

  const filtered = useFilterMentorList(data, assignments);

  const review = useEditItem2<Mentor>({
    dontResetOnSave: true,
    getApiPath: item => `/api/mentor/manage/${item._id}`,
  });

  
  return {
    ...mentors,
    data: filtered.data,
    filters: filtered,
    profileConfiguration,
    schema,
    review: {
      ...review,
      save: (extraChanges?: Partial<Mentor>) => review.save(extraChanges).then(x => { mentors.reload(); return x; }),
    },

    assignments,
  }
}

