import React from 'react';
import styled from '@emotion/styled';
import LogoImg from '../../metamentor_m_white.svg';
import LogoImg2 from '../../metamentor_color.svg';

const LogoTag = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 6px 6px;
`;

export const Logo = (props: {version?: "small-white" | "large-color"}) => (
    <LogoTag src={props.version === "large-color" ? LogoImg2 : LogoImg} className="logo" alt="toOlympus" />
);